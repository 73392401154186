<form (ngSubmit)="Enregistrer(myForm.form.valid, myForm._directives )" #myForm="ngForm">
    <div class="card-head" *ngIf="!ModeAjout">
        <div class="row">
            <div class="col-9 mt-2">
                <p>
                       {{"languages.gamme.gameNum" | translate}}:
                    <label class="label-card-info">
                        {{initialGamme.real_id}}
                    </label>
                </p>
                <p>
                      {{"languages.gamme.nomGamme" | translate}} :
                    <label class="label-card-info">
                        {{initialGamme.nom}}
                    </label>
                </p>
            </div>
            <div class="col-3 text-left">
                <p>  {{'languages.gamme.lignePoduit' | translate}}:
                    <label class="label-card-info">
                        {{initialGamme.type_contrat}}
                    </label>
                </p>
                <p>  {{'languages.listing.Compagnie' | translate}}:
                    <label class="label-card-info">
                        {{initialGamme.denomination}}
                    </label>
                </p>
            </div>
        </div>
    </div>
    <div class="card-head text-center">
        <div class="row details-header">
            <button type="button" (click)="retourToList()" class="btn-dt-retourr mt-1" >
                <div class="icon-position">
                    <i class="fa-solid fa-arrow-left fa-icon-style fa-lg"></i>
                </div>
            </button>
            <div class="row">
                <div class=" row details-spinner">
                    <div class="col">
                        <ng-container *ngIf="show_loading_add">
                            <div class="col-12">
                                <div class="spinner-style">
                                    <mat-spinner [diameter]="30"></mat-spinner>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <button type="button" class="btn-load" (click)="clearForm()">
                        <i class="fa-solid fa-rotate-right"></i>
                    </button>
                </div>
                <button class="btn-dt-save" type="submit" [disabled]="show_loading_add">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                    {{'languages.buttons.enregistrer' | translate}}
                </button>
            </div>
        </div>
    </div>
    <div class="card p-3 border-0 m-3 mb-5">
        <div class="row">
            <div class="col-12">
                <span class="text-danger">( * )  {{'languages.affaire.champsOblig' | translate}}</span>
            </div>
        </div>
        <mat-tab-group mat-align-tabs="center">
            <mat-tab label="{{'languages.gamme.infoGamme' | translate}}">
                <div class="row mt-3">
                    <div class="col-md-6">
                        <label> {{'languages.gamme.logo' | translate}}</label>
                        <div class="row">
                            <div class="col-2 pt-1 px-3 border-right-0 border border-primary">
                                <i class="fa-solid fa-image fa fa-2x pt-3 pl-0"></i>
                            </div>
                            <div class="col-8 pt-2 px-3 border-right-0 border-left-0 border border-primary">
                                <input type="file" accept=".jpg, .jpeg, .png" id="idlogo" (change)="getLogo($event)"
                                    hidden />
                                <label for="idlogo" class="select-label py-3 pl-4">
                                    {{'languages.organisme.selectLogo' | translate}}
                                </label>
                            </div>
                            <div class="col-2 pt-3 pl-3 border-left-0 border border-primary">
                                <img class="img" *ngIf="show_image!=null" data-toggle="modal" data-target="#Actions"
                                    src="{{show_image}}">
                                <img class="img" *ngIf="show_image==null" data-toggle="modal" data-target="#Actions"
                                    src="../../../../assets/img/no-image.png">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <mat-form-field appearance="standard" class="w-100 mt-4">
                                <mat-label>{{'languages.gamme.nomGamme' | translate}}</mat-label>
                                <input type="text" matInput [(ngModel)]="Gamme.nom" name="nom_gamme" required
                                    [formControl]="nom_gamme">
                                <mat-error *ngIf=" (nom_gamme.dirty || nom_gamme.touched)">
                                    <div *ngIf="nom_gamme.hasError('required')">
<i class="fa-solid fa-triangle-exclamation"></i>
                                         {{'languages.gamme.nomGammeOblig' | translate}}
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <mat-form-field class="w-100" appearance="standard">
                                <mat-label>{{'languages.gamme.lignePoduit' | translate}} *</mat-label>
                                <input type="text" matInput [(ngModel)]="initial_contrat"
                                    [formControl]="affecterLigneAFormControl" name="type" [matAutocomplete]="autoLigne">
                                <mat-autocomplete (optionSelected)="selectContrat($event.option.value)"
                                    autoActiveFirstOption [displayWith]="displayLingeFn" #autoLigne="matAutocomplete">
                                    <mat-option *ngFor="let option of listLignedePorduitFilter | async"
                                        [value]="option">
                                        {{option.type}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error
                                    *ngIf=" (affecterLigneAFormControl.dirty || affecterLigneAFormControl.touched) ">
                                    <div *ngIf="affecterLigneAFormControl.hasError('required')">
<i class="fa-solid fa-triangle-exclamation"></i>
                                         {{'languages.gamme.ligneProduitOblig' | translate}}
                                    </div>
                                    <div
                                        *ngIf="affecterLigneAFormControl.hasError('invalidAutocompleteObject') &&  !affecterLigneAFormControl.hasError('required')">
                                        <i class="fa-solid fa-triangle-exclamation"></i> 
                                         {{'languages.gamme.verifyLigneProduit' | translate}}
                                    </div>
                                </mat-error>

                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group" [ngClass]="{'disabled':!ModeAjout}">
                            <mat-form-field class="w-100" appearance="standard">
                                <mat-label>{{'languages.listing.Compagnie' | translate}} *</mat-label>
                                <input type="text" matInput [(ngModel)]="initial_campagne"
                                    [formControl]="affecterACompagnie" name="compagnie"
                                    [matAutocomplete]="autoCompagnie">
                                <mat-autocomplete [displayWith]="displayCompagnieFn" autoActiveFirstOption
                                    #autoCompagnie="matAutocomplete">
                                    <mat-option *ngFor="let option of listCompagnieFilter | async" [value]="option">
                                        {{option.denomination}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf=" (affecterACompagnie.dirty || affecterACompagnie.touched) ">
                                    <div *ngIf="affecterACompagnie.hasError('required')">
<i class="fa-solid fa-triangle-exclamation"></i>
                                         {{'languages.gamme.compagnieOblig' | translate}}
                                    </div>
                                    <div
                                        *ngIf="affecterACompagnie.hasError('invalidAutocompleteObject') &&  !affecterACompagnie.hasError('required')">
                                        <i class="fa-solid fa-triangle-exclamation"></i>
                                         {{'languages.gamme.verifyCompagnie' | translate}}
                                    </div>
                                </mat-error>

                            </mat-form-field>


                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <mat-form-field appearance="standard" class="w-100">
                                <mat-label>{{'languages.garantie.Ageminimale' | translate}}</mat-label>
                                <input type="text" matInput [(ngModel)]="Gamme.age_min" name="age_min"
                                    (keypress)="numberOnly($event)">
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <mat-form-field appearance="standard" class="w-100">
                                <mat-label> {{'languages.garantie.Agemaximale' | translate}}</mat-label>
                                <input type="text" matInput [(ngModel)]="Gamme.age_max" name="age_max"
                                    (keypress)="numberOnly($event)">

                            </mat-form-field>
                            <div *ngIf="!validateAgeRange()">
                                <p style="color: red;"> {{'languages.garantie.diffAgeMaxMin' | translate}} </p>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">

                            <mat-form-field appearance="standard" class="w-100">
                                <mat-label>{{'languages.gamme.conditionSouscription' | translate}}</mat-label>
                                <textarea matInput [(ngModel)]="Gamme.conditions_souscription"
                                    name="conditions_souscription"></textarea>
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <mat-form-field appearance="standard" class="w-100">
                                <mat-label> {{'languages.ticket.description' | translate}}</mat-label>
                                <textarea matInput [(ngModel)]="Gamme.description" name="description"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">

                            <mat-form-field appearance="standard" class="w-100">
                                <mat-label> {{'languages.gamme.identifiantExterne' | translate}}</mat-label>
                                <input type="text" matInput [(ngModel)]="Gamme.id_externe" name="id_externe">
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="col-md-6 mt-4 d-flex justify-content-around">
                        <div class=" d-inline form-group">
                            <label class="mx-2">{{'languages.gamme.gammePro' | translate}}</label>
                            <label class="switch  ">
                                <input type="checkbox" name="gammepro" [checked]="Gamme.pro == '1'"
                                    (change)="isPro($event) ">
                                <span class="slider round"></span>
                            </label> 
                        </div>

                        <div class="ml-3 d-inline form-group">
                            <label class="mx-2">{{'languages.gamme.Actif' | translate}}</label>
                            <label class="switch">
                                <input type="checkbox" name="gammeactif" [checked]="Gamme.active == '1'"
                                    (change)="isActive($event)">
                                <span class="slider round"></span>
                            </label>
                          
                        </div>
                        <div class="ml-3 d-inline form-group">
                            <label class="mx-2">{{'languages.gamme.renewable' | translate}}</label>
                            <label class="switch">
                                <input type="checkbox" name="Renewable" [checked]="Gamme.Renewable == '1'"
                                    (change)="isRenewable($event)">
                                <span class="slider round"></span>
                            </label>
                            
                        </div>
                    </div>

                </div>
                <div class="row" *ngIf=" Gamme.Renewable == '1'">
                    <!-- RenewMode Field -->
                    <div class="col-md-6 col-sm-12">
                      <div class="form-group">
                        <mat-form-field appearance="fill" class="w-100">
                          <mat-label>{{ 'languages.gamme.RenewMode' | translate }}</mat-label>
                          <mat-select
                            name="RenewMode"
                            [(ngModel)]="Gamme.RenewMode"
                            placeholder="{{ 'languages.gamme.RenewMode' | translate }}"
                            #renewModeCtrl="ngModel"
                            required
                          >
                            <mat-option value="Tacite">{{ 'languages.gamme.Tacite' | translate }}</mat-option>
                            <mat-option value="Avis">{{ 'languages.gamme.Avis' | translate }}</mat-option>
                          </mat-select>
                          <mat-error *ngIf="renewModeCtrl.invalid && renewModeCtrl.touched">
                            {{ 'languages.gamme.RenewModeOblig' | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                
                    <!-- RenewNoticeDelay Field -->
                    <div class="col-md-6 col-sm-12">
                      <div class="form-group">
                        <mat-form-field appearance="standard" class="w-100">
                          <mat-label>{{ 'languages.gamme.RenewNoticeDelay' | translate }}</mat-label>
                          <input
                            type="text"
                            matInput
                            [(ngModel)]="Gamme.RenewNoticeDelay"
                            name="RenewNoticeDelay"
                            placeholder="{{ 'languages.gamme.RenewNoticeDelayPlaceholder' | translate }}"
                            #renewNoticeDelayCtrl="ngModel"
                            required
                            (keypress)="numberOnly($event)"
                          />
                          <mat-error *ngIf="renewNoticeDelayCtrl.invalid && renewNoticeDelayCtrl.touched">
                            {{ 'languages.gamme.RenewNoticeDelayOblig' | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                
                  <!-- Conditional Fields for Avis -->
                  <div class="row" *ngIf="Gamme.Renewable == '1' && Gamme.RenewMode === 'Avis'">
                    <!-- NoticeEmailTemplate Field -->
                    <div class="col-md-6 col-sm-12">
                      <div class="form-group">
                        <mat-form-field appearance="standard" class="w-100">
                          <mat-label>{{ 'languages.gamme.NoticeEmailTemplate' | translate }}</mat-label>
                          <textarea
                            type="text"
                            matInput
                            [(ngModel)]="Gamme.Notice_email_template"
                            name="NoticeEmailTemplate"
                            required
                            placeholder="{{ 'languages.gamme.NoticeEmailTemplate' | translate }}"
                            #noticeEmailTemplateCtrl="ngModel"
                            rows="5" 
                          ></textarea>
                          <mat-error *ngIf="noticeEmailTemplateCtrl.invalid && noticeEmailTemplateCtrl.touched">
                            {{ 'languages.gamme.NoticeEmailTemplateOblig' | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                
                    <!-- NoticeMailTemplate Field -->
                    <div class="col-md-6 col-sm-12">
                      <div class="form-group">
                        <mat-form-field appearance="standard" class="w-100">
                          <mat-label>{{'languages.gamme.NoticeMailTemplate' | translate }}</mat-label>
                          <textarea
                            type="text"
                            matInput
                            [(ngModel)]="Gamme.Notice_mail_template"
                            name="NoticeMailTemplate"
                            required
                            placeholder="{{'languages.gamme.NoticeMailTemplate' | translate }}"
                            #noticeMailTemplateCtrl="ngModel"
                            rows="5" 
                            ></textarea>
                          <mat-error *ngIf="noticeMailTemplateCtrl.invalid && noticeMailTemplateCtrl.touched">
                            {{ 'languages.gamme.noticeMailTemplateOblig' | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                </div>
                <div class="row w-100">
                    <app-group-garantie-shared class="w-100 border-0"
                    [listeGroupefromParent]="listeGroupe" 
                    [garanties_herite]="garanties_herite"
                    [id_contrat]="id"
                    [id_gamme]="id_gamme" (listeGroupFromP)="listeGroupFromP($event)"
                    (listeGroupFrom)="addItem($event)" (reassurance)="reassurance($event)">
                    </app-group-garantie-shared>
                </div>
            </mat-tab>
            <mat-tab label="{{'languages.prospect.infoComp' | translate}}">
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="form-group">
                            <div class="mt-3">
                                <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
                              </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{'languages.gamme.infoTarificateur_comparateur' | translate}}">
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="w-100" appearance="standard">
                                <mat-label>{{'languages.gamme.urlTarif' | translate}}</mat-label>
                                <input type="text" matInput [(ngModel)]="Gamme.url_tarification"
                                    #url_tarification="ngModel" name="url_tarification">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{'languages.gamme.documentGammes' | translate}}" >
                <div class="row mt-4">
                    <div class="col-md-6 col-sm-12 accordion" id="folder_tableaux_de_garanties_collapse">
                        <div class="mb-4 ">
                            <div class="documentDiv" id="headingFolder">
                                <div class="row flex-nowrap">
                                    <div class="col-1 mt-1 pl-0">
                                        <i class="fa-solid fa-folder folder-icon"></i>
                                    </div>
                                    <div class="col-10 text-left cursor" data-toggle="collapse"
                                        data-target="#tableaux_de_garanties_collapse" aria-expanded="false"
                                        aria-controls="collapseTwo">
                                        <p class="text-dark ml-3 mt-2">
                                             {{'languages.gamme.tableauGaranties' | translate}}
                                        </p>
                                    </div>
                                    <div class="col-1 text-right mt-0 position-relative pl-0">
                                        <button type="button" mat-icon-button
                                            [matMenuTriggerFor]="menu_tableaux_de_garanties"
                                            aria-label="Example icon-button with a menu">
                                            <i class="fa-solid fa-ellipsis-vertical bi-three-dots-vertical"></i>
                                                
                                        </button>
                                        <mat-menu #menu_tableaux_de_garanties="matMenu">
                                            <button mat-menu-item>
                                                <input type="file" accept=".pdf" id="tableaux_de_garanties"
                                                    (change)='getTableauGarantie($event)' name="tableaux_de_garanties"
                                                    hidden ngModel #tableaux_de_garanties="ngModel" />
                                                <label for="tableaux_de_garanties" class="add-file">
<i class="fa-solid fa-circle-plus"></i>
                                                    {{'languages.buttons.addFile' | translate}}
                                                </label>
                                            </button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                            <div id="tableaux_de_garanties_collapse" *ngIf="Gamme.tableaux_de_garanties"
                                class="collapse" aria-labelledby="headingFolder"
                                data-parent="#folder_tableaux_de_garanties_collapse">
                                <div>
                                    <div class="row d-flex d-flex align-items-center blocDocChild my-2">
                                        
                                        <div class="col-11 pl-4">
                                            <ng-container *ngIf="Gamme.tableaux_de_garanties">
                                                <a href="{{ Gamme.tableaux_de_garanties }}" target="_blank"
                                                    class="file-name">
                                                    {{expandName(Gamme.tableaux_de_garanties_name)}}
                                                </a>

                                            </ng-container>


                                        </div>
                                        <div class="col-1"> <button type="button" class="deleteFileDoc"
                                                (click)="deletefile('tableauGarantie')" *ngIf="tableauGarantie">
                                                <img src="assets/icons/closeIcon.svg" alt="close icon">
                                            </button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 accordion" id="folder_formulaire_adhesion_collapse">
                        <div class="mb-4 ">
                            <div class="documentDiv" id="headingFolder">
                                <div class="row flex-nowrap">
                                    <div class="col-1 mt-1 pl-0">
                                        
                                        <i class="fa-solid fa-folder folder-icon"></i>
                                    </div>
                                    <div class="col-10 text-left cursor" data-toggle="collapse"
                                        data-target="#formulaire_adhesion_collapse" aria-expanded="false"
                                        aria-controls="collapseTwo">
                                        <p class="text-dark ml-3 mt-2">
                                             {{'languages.gamme.formulaireAdhesion' | translate}}
                                        </p>
                                    </div>
                                    <div class="col-1 text-right mt-0 position-relative pl-0">
                                        <button type="button" mat-icon-button
                                            [matMenuTriggerFor]="menu_formulaire_adhesion"
                                            aria-label="Example icon-button with a menu">
                                            <i class="fa-solid fa-ellipsis-vertical bi-three-dots-vertical"></i>
                                        </button>
                                        <mat-menu #menu_formulaire_adhesion="matMenu">
                                            <button mat-menu-item>
                                                <input type="file" id="formulaire_adhesion"
                                                    (change)='getFormulaireAdhesion($event)' name="formulaire_adhesion"
                                                    hidden ngModel #formulaire_adhesion="ngModel" accept=".pdf" />
                                                <label for="formulaire_adhesion" class="add-file">
<i class="fa-solid fa-circle-plus"></i>
                                                    {{'languages.buttons.addFile' | translate}}
                                                </label>
                                            </button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                            <div id="formulaire_adhesion_collapse" *ngIf="Gamme.formulaire_adhesion" class="collapse"
                                aria-labelledby="headingFolder" data-parent="#folder_formulaire_adhesion_collapse">
                                <div>
                                    <div class="row d-flex d-flex align-items-center blocDocChild my-2">
                                       
                                        <div class="col-11 pl-4">



                                            <ng-container *ngIf="Gamme.formulaire_adhesion">
                                                <a href="{{ Gamme.formulaire_adhesion }}" target="_blank"
                                                    class="file-name">
                                                    {{expandName(Gamme.formulaire_adhesion_name)}}
                                                </a>

                                            </ng-container>

                                        </div>
                                        <div class="col-1"><button type="button" class="deleteFileDoc"
                                                (click)="deletefile('formulaireAdhesion')" *ngIf="formulaireAdhesion">
                                                <img src="assets/icons/closeIcon.svg" alt="close icon">
                                            </button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 accordion" id="folder_notice_information_collapse">
                        <div class="mb-4 ">
                            <div class="documentDiv" id="headingFolder">
                                <div class="row flex-nowrap">
                                    <div class="col-1 mt-1 pl-0">
                                        <i class="fa-solid fa-folder folder-icon"></i>
                                    </div>
                                    <div class="col-10 text-left cursor" data-toggle="collapse"
                                        data-target="#notice_information_collapse" aria-expanded="false"
                                        aria-controls="collapseTwo">
                                        <p class="text-dark ml-3 mt-2">
                                             {{'languages.gamme.noticeInfo' | translate}}
                                        </p>
                                    </div>
                                    <div class="col-1 text-right mt-0 position-relative pl-0">
                                        <button type="button" mat-icon-button
                                            [matMenuTriggerFor]="menu_notice_information"
                                            aria-label="Example icon-button with a menu">
                                            <i class="fa-solid fa-ellipsis-vertical bi-three-dots-vertical"></i>
                                        </button>
                                        <mat-menu #menu_notice_information="matMenu">
                                            <button mat-menu-item>
                                                <input type="file" id="notice_information"
                                                    (change)='getNoticeInformation($event)' name="notice_information"
                                                    hidden ngModel #notice_information="ngModel" accept=".pdf" />
                                                <label for="notice_information" class="add-file">
<i class="fa-solid fa-circle-plus"></i>
                                                    {{'languages.buttons.addFile' | translate}}
                                                </label>
                                            </button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                            <div id="notice_information_collapse" *ngIf="Gamme.notice_information" class="collapse"
                                aria-labelledby="headingFolder" data-parent="#folder_notice_information_collapse">
                                <div>
                                    <div class="row d-flex d-flex align-items-center blocDocChild my-2">
                                       
                                        <div class="col-11 pl-4">
                                          
                                                <ng-container *ngIf="Gamme.notice_information">
                                                    <a href="{{ Gamme.notice_information }}" target="_blank" class="file-name">
                                  {{expandName(Gamme.notice_information_name)}}
                              </a>

                                                </ng-container>
                                            </div>
                                        
                                        <div class="col-1">
                                            <button type="button" class="deleteFileDoc"
                                                (click)="deletefile('noticeInformation')" *ngIf="noticeInformation">
                                                <img src="assets/icons/closeIcon.svg" alt="close icon">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 accordion" id="folder_avantages_tiers_payant_collapse">
                        <div class="mb-4 ">
                            <div class="documentDiv" id="headingFolder">
                                <div class="row flex-nowrap">
                                    <div class="col-1 mt-1 pl-0">
                                        <i class="fa-solid fa-folder folder-icon"></i>
                                    </div>
                                    <div class="col-10 text-left cursor" data-toggle="collapse"
                                        data-target="#avantages_tiers_payant_collapse" aria-expanded="false"
                                        aria-controls="collapseTwo">
                                        <p class="text-dark ml-3 mt-2">
                                             {{'languages.gamme.avantagetiersPayant' | translate}}
                                        </p>
                                    </div>
                                    <div class="col-1 text-right mt-0 position-relative pl-0">
                                        <button type="button" mat-icon-button
                                            [matMenuTriggerFor]="menu_avantages_tiers_payant"
                                            aria-label="Example icon-button with a menu">
                                            <i class="fa-solid fa-ellipsis-vertical bi-three-dots-vertical"></i>
                                        </button>
                                        <mat-menu #menu_avantages_tiers_payant="matMenu">
                                            <button mat-menu-item>
                                                <input type="file" accept=".pdf" id="avantages_tiers_payant"
                                                    (change)='getAvantages($event)' name="avantages_tiers_payant" hidden
                                                    ngModel #avantages_tiers_payant="ngModel" />
                                                <label for="avantages_tiers_payant" class="add-file">
<i class="fa-solid fa-circle-plus"></i>
                                                    {{'languages.buttons.addFile' | translate}}
                                                </label>
                                            </button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                            <div id="avantages_tiers_payant_collapse" *ngIf="Gamme.avantages_tiers_payant"
                                class="collapse" aria-labelledby="headingFolder"
                                data-parent="#folder_avantages_tiers_payant_collapse">
                                <div>
                                    <div class="row d-flex d-flex align-items-center blocDocChild my-2">
                                      
                                        <div class="col-11 pl-4">
                                       
                                                <ng-container *ngIf="Gamme.avantages_tiers_payant">
                                                    <a href="{{ Gamme.avantages_tiers_payant }}" target="_blank" class="file-name">
                                  {{expandName(Gamme.avantages_tiers_payant_name)}}
                              </a>
                                              
                                                </ng-container>
                                       
                                        </div>
                                        <div class="col-1"> <button type="button" class="deleteFileDoc"
                                                (click)="deletefile('avantages')" *ngIf="avantages">
                                                <img src="assets/icons/closeIcon.svg" alt="close icon">
                                            </button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 accordion" id="folder_ipid_collapse">
                        <div class="mb-4 ">
                            <div class="documentDiv" id="headingFolder">
                                <div class="row flex-nowrap">
                                    <div class="col-1 mt-1 pl-0">
                                        <i class="fa-solid fa-folder folder-icon"></i>
                                    </div>
                                    <div class="col-10 text-left cursor" data-toggle="collapse"
                                        data-target="#ipid_collapse" aria-expanded="false" aria-controls="collapseTwo">
                                        <p class="text-dark ml-3 mt-2">
                                            {{'languages.gamme.IPID' | translate}}
                                        </p>
                                    </div>
                                    <div class="col-1 text-right mt-0 position-relative pl-0">
                                        <button type="button" mat-icon-button [matMenuTriggerFor]="menu_pid"
                                            aria-label="Example icon-button with a menu">
                                            <i class="fa-solid fa-ellipsis-vertical bi-three-dots-vertical"></i>
                                        </button>
                                        <mat-menu #menu_pid="matMenu">
                                            <button mat-menu-item>
                                                <input type="file" accept=".pdf" id="id5" (change)='getIpid($event)'
                                                    name="id5" hidden ngModel />
                                                <label for="id5" class="add-file">
<i class="fa-solid fa-circle-plus"></i>
                                                    {{'languages.buttons.addFile' | translate}}
                                                </label>
                                            </button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                            <div id="ipid_collapse" *ngIf="Gamme.ipid" class="collapse" aria-labelledby="headingFolder"
                                data-parent="#folder_ipid_collapse">
                                <div>
                                    <div class="row d-flex d-flex align-items-center blocDocChild my-2">
                                     
                                        <div class="col-11 pl-4">
                                          
                                                <ng-container *ngIf="Gamme.ipid">
                                                    <a href="{{ Gamme.ipid }}" target="_blank" class="file-name">
                                  {{expandName(Gamme.ipid_name)}}
                              </a>
                                                    

                                                </ng-container>
                                       
                                        </div>
                                        <div class="col-1"><button type="button" class="deleteFileDoc"
                                                (click)="deletefile('ipid')" *ngIf="ipid">
                                                <img src="assets/icons/closeIcon.svg" alt="close icon">
                                            </button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 accordion" id="folder_support_commercial_collapse">
                        <div class="mb-4 ">
                            <div class="documentDiv" id="headingFolder">
                                <div class="row flex-nowrap">
                                    <div class="col-1 mt-1 pl-0">
                                        <i class="fa-solid fa-folder folder-icon"></i>
                                    </div>
                                    <div class="col-10 text-left cursor" data-toggle="collapse"
                                        data-target="#support_commercial_collapse" aria-expanded="false"
                                        aria-controls="collapseTwo">
                                        <p class="text-dark ml-3 mt-2">
                                             {{'languages.gamme.supportCommeciale' | translate}}
                                        </p>
                                    </div>
                                    <div class="col-1 text-right mt-0 position-relative pl-0">
                                        <button type="button" mat-icon-button
                                            [matMenuTriggerFor]="menu_support_commercial"
                                            aria-label="Example icon-button with a menu">
                                            <i class="fa-solid fa-ellipsis-vertical bi-three-dots-vertical"></i>
                                        </button>
                                        <mat-menu #menu_support_commercial="matMenu">
                                            <button mat-menu-item>
                                                <input type="file" accept=".pdf" id="support_commercial"
                                                    (change)='getSupportCommercial($event)' name="support_commercial"
                                                    hidden ngModel #support_commercial="ngModel" />
                                                <label for="support_commercial" class="add-file">
<i class="fa-solid fa-circle-plus"></i>
                                                    {{'languages.buttons.addFile' | translate}}
                                                </label>
                                            </button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                            <div id="support_commercial_collapse" *ngIf="Gamme.support_commercial" class="collapse"
                                aria-labelledby="headingFolder" data-parent="#folder_support_commercial_collapse">
                                <div>
                                    <div class="row d-flex d-flex align-items-center blocDocChild my-2">
                                       
                                        <div class="col-11 pl-4">
                                      
                                                <ng-container *ngIf="Gamme.support_commercial">
                                                    <a href="{{ Gamme.support_commercial }}" target="_blank" class="file-name">
                                  {{expandName(Gamme.support_commercial_name)}}
                              </a>
                                                    
                                                </ng-container>
                                           
                                        </div>
                                        <div class="col-1"><button type="button" class="deleteFileDoc"
                                                (click)="deletefile('commerce')" *ngIf="supportCommercial">
                                                <img src="assets/icons/closeIcon.svg" alt="close icon">
                                            </button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{'languages.document.Formulaire' | translate}}" *ngIf="formulaireObj">
                <shared-globalProduit-form 
                [formBuilderData]="Gamme.form" 
                (emitFormBuilder)="getGammeForm($event)" 
                (itemerror)="onGetItemError($event)">
            </shared-globalProduit-form>

            </mat-tab>
        </mat-tab-group>
     
    </div>



    <div class="modal fade" id="Actions" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog model-lg actions-dialog" role="document">
            <div class="modal-content w-100">
                <div class="modal-header">
                    <h2 style="margin-left: 15px"> {{'languages.gamme.logoProduit' | translate}}</h2>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row justify-content-center ">
                        <img class="img" style="width: 200px;height: 200px;" src="{{show_image}}">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal"> {{'languages.buttons.fermer' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</form>