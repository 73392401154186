import { AfterContentInit, Component, ElementRef, OnInit, ViewChild , TemplateRef, HostListener } from '@angular/core';
import { Observable, Subject, Subscription, forkJoin, of } from 'rxjs';
import { ApiAdminBpmService } from 'src/app/services/ApiAdminBpm/api-admin-bpm.service';
 
import { ApiConfigurationService } from 'src/app/services/apiConfiguration/api-configuration.service';
import { FormModel } from 'src/app/entity/Formbuilder/FormModel';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { TranslateService } from '@ngx-translate/core';
import { catchError, debounceTime, map, startWith } from 'rxjs/operators';
import { FormbuilderValidationService } from 'src/app/services/formbuilder-validation.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { Router } from '@angular/router';

class Config {
  key: string;
  value: any;
}
class Instance {
  report_server: string;
  subscription_url: string;
  currentCurrency: string;
  formatCurrency: string;
  country: string;
  api_url: String;
}

class Rule {
  id: string;
  label: string;
  rule: string;
  rule_type: number;
}
@Component({
  selector: 'app-add-configuration',
  templateUrl: './add-configuration.component.html',
  styleUrls: ['./add-configuration.component.css'],
})
export class AddConfigurationComponent implements OnInit, AfterContentInit {
  panelOpenState = false;
  formBuilderDataPhysique: any;
  formBuilderDataMorale: any;
  formBuilderDataOrganisme: any;
  formBuilderDataCompagnie: any;
  formBuilderDataQuality : any;
  formBuilderDataGamme : any;
  listOfUnsubscribeWebService: Subscription[] = [];
   
  config = new Config();
  keyFormOrganisme: string;
  keyFromPhysique: string;
  keyFormMorale: string;
  keyFormCompagnie: string;
  keyFormGamme: string;
  keyInstance: string;
  keyFormQuality: string;
  infoSpecif: boolean = true;
  infoSpecifEn: boolean = false;
  organisme: boolean = false;
  compagnie: boolean = false;
  instance: boolean;
  quality:boolean =false;
  gamme:boolean =false;
  formErrorphysique: boolean = false;
  formErrormorale: boolean = false;
  formErrororganisme: boolean = false;
  formErrorQuality: boolean = false;
  formErrorGamme: boolean = false;
  rules: boolean = false
  // subscription_url: any;
  @ViewChild('souscription_url') souscription_url;
  @ViewChild('report_server') report_server;
  @ViewChild('api_url') api_url;


  @ViewChild('monacoeditorpopuppre') monacoeditorpopuppre: TemplateRef<any>;
  @ViewChild('monacoeditorpopupprebigger') monacoeditorpopupprebigger: TemplateRef<any>;
  @ViewChild('monacoeditorpost') monacoeditorpost: TemplateRef<any>;
  @ViewChild('monacoeditorpostbigger') monacoeditorpostbigger: TemplateRef<any>;

  currentCurrency: string;
  formatCurrency: string;
  country: string;
  bodyInstance = new Instance();
  formulaireTrigger = false;
  urlvalid2: boolean;
  urlvalid1: boolean;
  urlvalid3: boolean;
  alerts: any;
  buttons: any;
  formErrorCompagnie: boolean;
  showLoading: boolean;
  show_loading_adding:boolean

  dialogWidth: string = 'fit-content'; 
  dialogHeight: string = 'auto';
  editorOptions = {theme: 'vs-dark', language: 'python', automaticLayout: true};

  ruleStr: string = ` # The 'valid' variable determines the validation result.
  # Set 'valid' to True to allow the validation to proceed.
  # Set 'valid' to False to halt the process.
  # To invalidate the process with a custom message, raise a 'customResponse' exception.
`;

  rulesTypes: any;
  idRule: any;
  listRules : any 
  listRuleTypesFilter: Observable<any>;
  idRuleType: any;
  infoRule:  Rule =new Rule();;
  addRole: Rule =new Rule();
  addRule: boolean =false;
  modifyRule:  Rule =new Rule();show_loading: boolean;
  showDetails: boolean;
  
  limit = 5;
  page = 1;
  totalPages = 0;
  totalLength: any;
  intervale = 1;
  search: string="";
  inputSubject = new Subject<string>();


  constructor(
    private apiAdminBpmServices: ApiAdminBpmService,
    private apiConfigurationService: ApiConfigurationService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private translate: TranslateService,
    private validationService: FormbuilderValidationService,
    private dialog:MatDialog,
    private notificationsService: NotificationsService,
    private routes: Router,
  ) {
    
    this.inputSubject.pipe(
      debounceTime(1000) 
    ).subscribe((inputValue: string) => {
      this.filter(inputValue);
    });
  }

  onInputChange(value: string): void {
    this.inputSubject.next(value);
  }

  ngAfterContentInit(): void {}

  ngOnInit(): void {
    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
      this.sharedMenuObserverService.updateMenu(object.navbar.Configuration);
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.sharedMenuObserverService.updateMenu(object.navbar.Configuration);
      });
    });
    this.showloader()

    this.keyFromPhysique = environment.keyFormProspect;
    this.keyFormMorale = environment.keyFormEntreprise;
    this.keyFormOrganisme = environment.keyFormOrganisme;
    this.keyFormCompagnie = environment.keyFormCompagnie;
    this.keyFormGamme = environment.keyFormGamme;
    this.keyInstance = environment.keyInstance;
   this.keyFormQuality =environment.keyFormQuality;
   
   this.apiConfigurationService.rulesTypes().subscribe((Response) => {
    this.rulesTypes = Response.rule_types;
    // this.getListRuleTypes()
    this.formulaireTrigger = true;
    this.hideloader()
  });
   this.show_loading=true
   this.getListRules()
 
 



    this.apiConfigurationService.getParamConfig(this.keyFromPhysique).subscribe((Response) => {
      this.formBuilderDataPhysique = Response.value;
      this.formulaireTrigger = true;
      this.hideloader()
    });
    this.apiConfigurationService.getParamConfig(this.keyFormMorale).subscribe((Response) => {
      this.formBuilderDataMorale = Response.value;
      this.hideloader()
    });
    this.apiConfigurationService.getParamConfig(this.keyFormOrganisme).subscribe((Response) => {
      this.formBuilderDataOrganisme = Response.value;
      this.hideloader()
    });
    this.apiConfigurationService.getParamConfig(this.keyFormCompagnie).subscribe((Response) => {
      this.formBuilderDataCompagnie = Response.value;
      this.hideloader()
    });
    this.apiConfigurationService.getParamConfig(this.keyFormQuality).subscribe((Response) => {
      this.formBuilderDataQuality = Response.value;
      this.hideloader()
    });
    this.apiConfigurationService.getParamConfig(this.keyInstance).subscribe((Response) => {
      this.bodyInstance = Response.value;
      this.hideloader()

    });
    this.apiConfigurationService.getParamConfig(this.keyFormGamme).subscribe((Response) => {
      this.formBuilderDataGamme = Response.value;
      this.hideloader()
    });
  }


  getListRules(){
    this.show_loading=true
    if(localStorage.getItem("page")) this.page = parseInt (localStorage.getItem("page"));
      if(localStorage.getItem("search")) this.search = localStorage.getItem("search");
    this.apiConfigurationService.rulesList(this.limit, this.page,this.search).subscribe((Response) => {
      this.listRules = Response.rules;
      this.totalLength = Response.totalCount;
      this.calculate_nb_pages(this.totalLength)
      this.show_loading=false
      this.formulaireTrigger = true;
      this.hideloader()
    });
  }

  rule: FormControl = new FormControl('');
  listRulesFilter: Observable<any[]> = null;

 

  getInfoRule(id){
    
    this.apiConfigurationService.getInfoRule(id).subscribe((Response) => {
      this.infoRule = Response.rule;
      this.modifyRule = JSON.parse(JSON.stringify(this.infoRule));
this.addRule =false
      this.ruleStr = this.infoRule?.rule
  this.showDetails=true
      this.hideloader()
    });
  }

  emptyRule(){
    this.modifyRule = JSON?.parse(JSON?.stringify(this.infoRule));
    this.ruleStr=this.infoRule?.rule
    this.showDetails = false;
  }

 
  
  ruleLabel :string =""

  createNewRule() {
  this.addRule=true
  this.modifyRule=new Rule();
 this.showDetails=true
  this.ruleStr=` # The 'valid' variable determines the validation result.
  # Set 'valid' to True to allow the validation to proceed.
  # Set 'valid' to False to halt the process.
  # To invalidate the process with a custom message, raise a 'customResponse' exception.
`

 
}



  ruleType = new FormControl(null);
 
  
  getDataPhysiqueForm(event: any): void {
    this.formBuilderDataPhysique = event;
  }

  getDataMoraleForm(event: any): void {
    this.formBuilderDataMorale = event;
  }

  getDataOrganismeForm(event: any): void {
    this.formBuilderDataOrganisme = event;
  }

  getDataCompagnieForm(event: any): void {
    this.formBuilderDataCompagnie = event;
  }
  
  getDataQualityForm(event: any): void {
    this.formBuilderDataQuality = event;
  }
  
  getDataGammeForm(event: any): void {
    this.formBuilderDataGamme = event;
  }

  ngOnDestroy(): void {
    this.apiAdminBpmServices.sharedFormbuilder = {};
    this.listOfUnsubscribeWebService.forEach((element) => {
      element?.unsubscribe();
    });
    this.validationService.resetFieldValidity();
   
    const currentRoute = this.routes.url;
    if (!currentRoute.includes('configuration')) {
      localStorage.removeItem('search');
      localStorage.removeItem('page');
    }
  }

  scroll(el: string): void {
    this.apiAdminBpmServices.sharedFormbuilder = {};
  
    this.organisme = false;
    this.infoSpecif = false;
    this.infoSpecifEn = false;
    this.instance = false;
    this.compagnie = false;
    this.quality = false;
    this.gamme = false;
    this.rules = false ; 
    this.validationService.resetFieldValidity();
    switch (el) {
      case 'organisme':
        this.organisme = true;
        break;
      case 'infoSpecifEn':
        this.infoSpecifEn = true;
        break;
      case 'infoSpecif':
        this.infoSpecif = true;
        break;
      case 'compagnie':
        this.compagnie = true;
        break;
      case 'quality':
        this.quality = true;
        break;
      case 'gamme':
        this.gamme = true;
        break;
      case 'rules':
        this.rules = true;
        break;
      default:
        this.instance = true;
        break;
    }
  }



  isPasswordInputVisible: boolean;
  password: string;
  showPassword: boolean;
  allow_to_change: boolean;

  togglePasswordInput() {
    this.isPasswordInputVisible = !this.isPasswordInputVisible;
    this.password = '';
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  validPass() {
    if (this.password) {
      if (this.password === environment.passwordUnlock) {
        this.allow_to_change = true;
        this.isPasswordInputVisible = false;
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.success;
        notificationMessage.title = this.alerts.Successoperation;
        notificationMessage.message = this.alerts.passwordValid;
        this.notificationsService.sendMessage(notificationMessage);
      } else {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = this.alerts.Operationechoue;
        notificationMessage.message = this.alerts.incorrectPassword;
        this.notificationsService.sendMessage(notificationMessage);
      }
    }
  }


  

  addConfig(type) {
    this.showLoading = true 
    this.showloader()
    if (type === 'organisme') {
      this.config.key = this.keyFormOrganisme;
      this.config.value = this.formBuilderDataOrganisme;
      this.apiConfigurationService.updateParamConfig(this.keyFormOrganisme, this.config).subscribe(
        (Response) => {
          this.apiAdminBpmServices.sharedIsSubmited = true;
          // this.apiAdminBpmServices.sharedFormbuilder = {};
        
          this.showLoading = false 
          this.hideloader()
          this.alertSuccess(this.alerts.infoCompOrganismUpdatedSuccess);
        },
        (error) => {
          console.log(error);
          this.showLoading = false 
          this.hideloader()
          this.alertError(this.alerts.errorUpdatingForm);
        }
      );
    } else if (type === 'morale') {
      this.config.key = this.keyFormMorale;
      this.config.value = this.formBuilderDataMorale;
      this.apiConfigurationService.updateParamConfig(this.keyFormMorale, this.config).subscribe(
        (Response) => {
          this.apiAdminBpmServices.sharedIsSubmited = true;
          // this.apiAdminBpmServices.sharedFormbuilder = {};
        
          this.showLoading = false 
          this.hideloader()
          this.alertSuccess(this.alerts.infoCompMoralUpdatedSucess);
        },
        (error) => {
          console.log(error);
          this.showLoading = false 
          this.hideloader()
          this.alertError(this.alerts.errorUpdatingForm);
        }
      );
    } else if (type === 'physique') {
      this.config.key = this.keyFromPhysique;
      this.config.value = this.formBuilderDataPhysique;
      this.apiConfigurationService.updateParamConfig(this.keyFromPhysique, this.config).subscribe(
        (Response) => {
          //  this.apiAdminBpmServices.sharedFormbuilder = {};
          

          this.apiAdminBpmServices.sharedIsSubmited = true;
          this.showLoading = false 
          this.hideloader()
          this.alertSuccess(this.alerts.infoCompPhysiqueUpdatedSucess);
        },
        (error) => {
          console.log(error);
          this.showLoading = false 
          this.hideloader()
          this.alertError(this.alerts.errorUpdatingForm);
        }
      );
    }if (type === 'compagnie') {
      this.config.key = this.keyFormCompagnie;
      this.config.value = this.formBuilderDataCompagnie;
      this.apiConfigurationService.updateParamConfig(this.keyFormCompagnie, this.config).subscribe(
        (Response) => {
          this.apiAdminBpmServices.sharedIsSubmited = true;
          // this.apiAdminBpmServices.sharedFormbuilder = {};
      
          this.showLoading = false 
          this.hideloader()
          this.alertSuccess(this.alerts.infoCompCompagnieUpdatedSuccess);
        },
        (error) => {
          console.log(error);
          this.showLoading = false 
          this.hideloader()
          this.alertError(this.alerts.errorUpdatingForm);
        }
      );
    }else if (type === 'qualite'){
      this.config.key = this.keyFormQuality;
      this.config.value = this.formBuilderDataQuality;
      this.apiConfigurationService.updateParamConfig(this.keyFormQuality, this.config).subscribe(
        (Response) => {
          this.apiAdminBpmServices.sharedIsSubmited = true;
          // this.apiAdminBpmServices.sharedFormbuilder = {};
        
          this.showLoading = false 
          this.hideloader()
          this.alertSuccess(this.alerts.infoQualiteUpdatedSucess);
        },
        (error) => {
          console.log(error);
          this.showLoading = false 
          this.hideloader()
          this.alertError(this.alerts.errorUpdatingForm);
        }
      );
    }else if (type === 'gamme'){
      this.config.key = this.keyFormGamme;
      this.config.value = this.formBuilderDataGamme;
      this.apiConfigurationService.updateParamConfig(this.keyFormGamme, this.config).subscribe(
        (Response) => {
          this.apiAdminBpmServices.sharedIsSubmited = true;
          // this.apiAdminBpmServices.sharedFormbuilder = {};
         
          this.showLoading = false 
          this.hideloader()
          this.alertSuccess(this.alerts.infoGammeUpdatedSucess);
        },
        (error) => {
          console.log(error);
          this.showLoading = false 
          this.hideloader()
          this.alertError(error?.error?.message);
        }
      );
    }
    else if (type === 'rules'){
      this.show_loading_adding =true
      if (this.addRule){
        const bodyRule={
          label :this.modifyRule.label ,
          rule_type :this.modifyRule.rule_type,
          rule: this.ruleStr
        }
        this.apiConfigurationService.createRule(bodyRule).subscribe(
          (Response) => {
            this.apiAdminBpmServices.sharedIsSubmited = true;
            // this.apiAdminBpmServices.sharedFormbuilder = {};
           
            this.showLoading = false 
            this.show_loading_adding =false
            this.hideloader()
            this.alertSuccess(this.alerts.infoRulesCreateddSucess);
            this.showDetails = false;
          },
          (error) => {
            this.hideloader()
            console.log(error);
            this.showLoading = false 
            this.show_loading_adding =false
            this.alertError(this.alerts.errorUpdatingRule);
          }
        );
      }else{
      const bodyRule={
        id_rule :this.modifyRule.id ,
        rule_type :this.modifyRule.rule_type,
        rule: this.ruleStr,
        label: this.modifyRule.label
      }
      this.apiConfigurationService.updateRule(bodyRule).subscribe(
        (Response) => {
          this.apiAdminBpmServices.sharedIsSubmited = true;
          // this.apiAdminBpmServices.sharedFormbuilder = {};
          this.hideloader()
          this.showLoading = false 
          this.show_loading_adding =false
          this.alertSuccess(this.alerts.infoRulesUpdatedSucess);
          this.showDetails = false;
        },
        (error) => {
          this.hideloader()
          console.log(error);
          this.showLoading = false 
          this.show_loading_adding =false
          this.alertError(this.alerts.errorUpdatingRule);
        }
      );
    }
    }

     else {
      this.config.key = this.keyInstance;
      this.config.value = this.bodyInstance;
      this.urlvalid1 = this.souscription_url.valueAccessor._elementRef.nativeElement.validity.valid;
      this.urlvalid2 = this.report_server.valueAccessor._elementRef.nativeElement.validity.valid;
      this.urlvalid3 = this.api_url.valueAccessor._elementRef.nativeElement.validity.valid;

      if (!this.urlvalid1 || !this.urlvalid2 || !this.urlvalid3) {
        this.alertError(this.alerts.invalidFields);
      } else {
        this.apiConfigurationService.updateParamConfig(this.keyInstance, this.config).subscribe(
          (Response) => {
           
            this.apiAdminBpmServices.sharedIsSubmited = true;
            this.showLoading = false 
            this.hideloader()
            this.alertSuccess(this.alerts.instandeUpdatedSucess);
          },
          (error) => {
            console.log(error);
            this.showLoading = false 
            this.hideloader()
            this.alertError(this.alerts.errorUpdating);
          }
        );
      }
    }
  }
  

  alertError(data, err?) {
    swal.fire({
      title: data,
      text: this.alerts.badHappened,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#d53a3a',
    });
  }

  alertSuccess(data, err?) {
    swal
      .fire({
        title: this.alerts.Successoperation,
        text: data,
        icon: 'success',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: this.buttons.fermer,
        confirmButtonColor: '#68a45b',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.ngOnInit();
        }
      });
  }

  onGetItemErrorPHysique($event) {
    this.formErrorphysique = $event;
  }

  onGetItemErrorMorale($event) {
    this.formErrormorale = $event;
  }

  onGetItemErrorOrganisme($event) {
    this.formErrororganisme = $event;
  }

  onGetItemErrorCompagnie($event) {
    this.formErrorCompagnie = $event;
  }

  onGetItemErrorQuality($event) {
    this.formErrorQuality = $event;
  }

  onGetItemErrorGamme($event) {
    this.formErrorGamme = $event;
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }


  openDialogmonaco(): void {
    const dialogRef: MatDialogRef<any> = this.dialog.open(this.monacoeditorpopuppre, {
      minWidth: this.dialogWidth,
      minHeight: this.dialogHeight,
    });
  }
  
  openDialogmonacobigger(): void {
    const dialogRef: MatDialogRef<any> = this.dialog.open(this.monacoeditorpopupprebigger, {
      minWidth: '100vw',
      minHeight: '90vh',
    });
  }


  updateMethod() {
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    localStorage.removeItem('search');
    localStorage.removeItem('page');
  }
  
  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  first() {
    this.setPage(1);

  }

  prev() {
    this.setPage(Math.max(1, this.page - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.page + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  setPage(val: number) {
    this.page = val;
    localStorage.setItem('page',this.page.toString())
    this.getListRules();
  }

  isFirst(): boolean {
    return this.page === 1;
  }

  isLast(): boolean {
    return this.page === this.totalPages;
  }

  changepage(number: number) {
    this.page = number; 
    localStorage.setItem('page',this.page.toString())
    this.getListRules();
  }
  
  getchangeLimite() {
    if (this.intervale <= this.totalPages) {
      this.page = this.intervale;
      if (this.page <= this.totalPages) {
        this.getListRules();
      }
    }
  }



  filter(event) {
    this.page = 1;

      localStorage.setItem('search',this.search);
      localStorage.setItem('page',this.page.toString())
      this.getListRules();
      
    }
  



}
