import { Component, ElementRef, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiRdvService } from '../../services/ApiRdv/api-rdv.service';
import frLocale from '@fullcalendar/core/locales/fr';
import esLocale from '@fullcalendar/core/locales/es';

import { take } from 'rxjs/operators';
import { RendezVousCalendar } from '../../entity/RendezVousCalendar';
import { SharedMenuObserverService } from '../../services/SharedMenuObserver/shared-menu-observer.service';
import * as moment from 'moment';
import { ThemePalette } from '@angular/material/core';
import { FormControl } from '@angular/forms';
import { NotificationsService } from '../../shared/NotificationsService/notifications.service';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
  providers: [],
})
export class CalendarComponent implements OnInit {
  RendezVous: any[] = [];
  calendarOptions: CalendarOptions = {
    datesSet: this.visibleRangeHandler.bind(this),
    selectable: true,
    initialView: 'timeGridWeek',
    eventClick: this.handleEventClick.bind(this),
    dateClick: this.handleDateClick.bind(this),
    select: this.handleDateSelect.bind(this),
    eventDrop: this.handleEventUpdate.bind(this),
    eventResize: this.handleEventUpdate.bind(this),
    timeZone: 'local',
    locale: 'fr',
    locales: [frLocale],
    events: this.RendezVous,
    headerToolbar: {
      center: 'dayGridMonth timeGridWeek',
      end: 'today prevYear,prev,next,nextYear', // will normally be on the right. if RTL, will be on the left
    },
    nowIndicator: true,
    editable: true,
  };

  private dateDebut: any = null;
  private dateEnd: any = null;
  private affecte: string = localStorage.getItem('id_user');
  RendezVousFullInfo: any[] = [];
  eventsFullInfo: any[] = [];
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  updateListRdv: boolean = true;
  navbarTxt: string;
  alerts: any;
  buttons: any;
  currentLanguage: string;

  constructor(
    private modalService: NgbModal,
    public NotificationsService: NotificationsService,
    private RdvService: ApiRdvService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private translate: TranslateService
  ) {}

  parseDate(dateStr: string, format: string = 'YYYY-MM-DDThh:mm:ssZ') {
    if (dateStr === (undefined || null)) {
      return null;
    } else {
      return moment(dateStr, format).toDate();
    }
  }

  parse(value: string): any {
    const parts = value.split(',');
    const dateparts = parts[0].split('/');
    const timeparts = parts[1].split(':');
    const date = new Date(+dateparts[2], +dateparts[1] - 1, +dateparts[0], +timeparts[0], +timeparts[1], +timeparts[2]);

    return date;
  }

  changerdatedebut(event) {
    const date = this.parse(event.targetElement.value);
    this.dateDebutControl.setValue(date);
  }

  changerdatefin(event) {
    const date = this.parse(event.targetElement.value);
    this.dateFinControl.setValue(date);
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.navbarTxt = object.navbar.Agenda;
        this.sharedMenuObserverService.updateMenu(this.navbarTxt);
        this.alerts = object.alerts;
        this.buttons = object.butttons;
        this.currentLanguage = this.translate.currentLang;
        this.changeCalendar();
      });
    });

    this.translate.get('languages').subscribe((object: any) => {
      this.navbarTxt = object.navbar.Agenda;
      this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      this.alerts = object.alerts;
      this.buttons = object.butttons;
      this.currentLanguage = this.translate.currentLang;
      this.changeCalendar();
    });
  }

  changeCalendar() {
    if (this.currentLanguage === 'en') {
      this.calendarOptions.locale = this.currentLanguage;
      this.calendarOptions.locales = [esLocale];
    } else {
      this.calendarOptions.locale = this.currentLanguage;
      this.calendarOptions.locales = [frLocale];
    }
  }

  selected_rdv: RendezVousCalendar = null;
  @ViewChild('OppUpdateRdvModal') OppUpdateRdvModal: ElementRef;
  @ViewChild('OppCreateRdvModal') OppCreateRdvModal: ElementRef;

  handleEventClick(info) {
    info.jsEvent.preventDefault();
    if (info.event.url !== (null || '' || 'null')) {
      window.open(info.event.url, '_blank');
    } else {
      if (info !== undefined && info !== null && info.event.id !== (undefined || null)) {
        this.selected_rdv = this.eventsFullInfo.filter((elem) => elem.id === info.event.id).pop();
        if (this.selected_rdv === (undefined || null)) {
          return;
        }
        this.dateDebutControl.setValue(info.event.start);
        this.dateFinControl.setValue(info.event.end);
        this.eventTitleControl.setValue(info.event.title);
        this.modalService.open(this.OppUpdateRdvModal, { ariaLabelledBy: 'modal-basic-title' });
        // window.open(info.event.url, '_blank');
      }
    }
  }

  handleDateClick(event) {
    if (this.calendarComponent.getApi().view.type === 'dayGridMonth') {
      this.calendarComponent.getApi().changeView('timeGridWeek', event.date.toISOString());
      return;
    }
    // if (new Date(event.date) <= new Date()) {
    //   let notificationMessage = new NotificationMessage();
    //   notificationMessage.type = NotificationType.warning;
    //   notificationMessage.message = 'Rendez-vous doit être supérieur à la date de l\'opportunité';
    //   this.NotificationsService.sendMessage(notificationMessage);
    // } else {
    // if (new Date(event.start) <= new Date()) {
    //   let notificationMessage = new NotificationMessage();
    //   notificationMessage.type = NotificationType.warning;
    //   notificationMessage.message = 'Rendez-vous doit être supérieur à la date de l\'opportunité';
    //   this.NotificationsService.sendMessage(notificationMessage);
    // } else {
    this.eventTitleControl.setValue('');
    this.dateDebutControl.setValue(event.date);
    this.modalService.open(this.OppCreateRdvModal, { ariaLabelledBy: 'modal-basic-title2' });
    // }
    // }
  }

  handleDateSelect(event) {
    if (this.calendarComponent.getApi().view.type === 'dayGridMonth') {
      return;
    }
    // if (new Date(event.date) <= new Date()) {
    //   let notificationMessage = new NotificationMessage();
    //   notificationMessage.type = NotificationType.warning;
    //   notificationMessage.message = 'Rendez-vous doit être supérieur à la date de l\'opportunité';
    //   this.NotificationsService.sendMessage(notificationMessage);
    // } else
    //   {
    //   if (new Date(event.start) <= new Date()) {
    //     let notificationMessage = new NotificationMessage();
    //     notificationMessage.type = NotificationType.warning;
    //     notificationMessage.message = 'Rendez-vous doit être supérieur à la date de l\'opportunité';
    //     this.NotificationsService.sendMessage(notificationMessage);
    //   } else
    // {
    this.dateDebutControl.setValue(event.start);
    this.dateFinControl.setValue(event.end);
    this.eventTitleControl.setValue('');
    this.modalService.open(this.OppCreateRdvModal, { ariaLabelledBy: 'modal-basic-title2' });
    // }
    // }
  }

  visibleRangeHandler(data) {
    const startDateList = data.startStr.split('T');
    const endDateList = data.endStr.split('T');
    if (startDateList[0] !== this.dateDebut && endDateList[0] !== this.dateEnd) {
      this.dateDebut = startDateList[0];
      this.dateEnd = endDateList[0];
      this.RechargerListeRdv();
    }
  }

  RechargerListeRdv() {
    this.updateListRdv = false;
    if (this.dateDebut !== null && this.dateEnd !== null) {
      this.RdvService.getListeRdv(this.affecte, this.dateDebut, this.dateEnd)
        .pipe(take(1))
        .subscribe((Response: RendezVousCalendar[]) => {
          this.RendezVousFullInfo = Response;
          this.buildCalendarRdv(Response);
          this.RdvService.getListEvenments(this.affecte, this.dateDebut, this.dateEnd)
            .pipe(take(1))
            .subscribe((Response: RendezVousCalendar[]) => {
              this.eventsFullInfo = Response;
              this.buildCalendarEvents(Response);
            });
        });
    }
  }

  list_upcoming_rdvs: RendezVousCalendar[] = [];

  private buildCalendarRdv(rendezVousCalendar: RendezVousCalendar[]) {
    this.RendezVous = [];
    this.list_upcoming_rdvs = [];
    rendezVousCalendar.forEach((element) => {
      const rdv = new RendezVousCalendar();
      rdv.title = element.title;
      rdv.id = element.id_opp_md5;
      rdv.backgroundColor = '#f37a6a';
      rdv.url = './opportunities/details/' + element.id_opp_md5 + '/planification';
      rdv.start = element.start;
      rdv.end = element.end;
      this.RendezVous.push(rdv);
      if (new Date() < this.parseDate(element.start)) {
        this.list_upcoming_rdvs.push(rdv);
      }
    });
  }

  private buildCalendarEvents(rendezVousCalendar: RendezVousCalendar[]) {
    if (rendezVousCalendar === null) {
      this.updateListRdv = true;
      this.calendarComponent.getApi()?.setOption('events', this.RendezVous);
      this.calendarComponent.getApi()?.setOption('visibleRange', {
        start: this.dateDebut,
        end: this.dateEnd,
      });
      return;
    }
    rendezVousCalendar.forEach((element) => {
      const rdv = new RendezVousCalendar();
      rdv.title = element.title;
      rdv.backgroundColor = '#aefcff';
      rdv.start = element.start;
      rdv.end = element.end;
      rdv.id = element.id;
      rdv.url = null;
      rdv.editable = true;
      rdv.startEditable = true;
      rdv.durationEditable = true;
      this.RendezVous.push(rdv);
    });
    this.updateListRdv = true;
    this.calendarComponent?.getApi().setOption('events', this.RendezVous);
    this.calendarComponent?.getApi().setOption('visibleRange', {
      start: this.dateDebut,
      end: this.dateEnd,
    });
  }

  /* mat-calendar */
  selectedDate: any;

  onSelect(event) {
    this.calendarComponent.getApi().gotoDate(event.toISOString());
  }

  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  public dateDebutControl = new FormControl();
  public eventTitleControl = new FormControl('');
  public dateFinControl = new FormControl();

  handleEventUpdate(event) {
    this.dateDebutControl.setValue(event.event.start);
    this.dateFinControl.setValue(event.event.end);
    this.eventTitleControl.setValue(event.event.title);
    const start = new Date(this.dateDebutControl.value);
    const end = new Date(this.dateFinControl.value);
    this.updateListRdv = false;
    this.RdvService.updateEvenments(
      event.event.id,
      this.eventTitleControl.value,
      start.toISOString(),
      end.toISOString()
    ).subscribe(
      (data) => {
        this.successAlert(this.alerts.updateEvent);
        this.RechargerListeRdv();
      },
      (error) => {
        this.errorAlert(this.alerts.updateEvent, error);
        this.updateListRdv = true;
      }
    );
  }

  updateRdvDetails() {
    if (this.dateDebutControl.value === null || this.dateFinControl.value === null) {
      this.errorAlert(this.alerts.wrongDate, '');
      return;
    }
    if (this.dateFinControl.value < this.dateDebutControl.value) {
      this.errorAlert(this.alerts.obligDateFin, '');
      return;
    }
    const start = new Date(this.dateDebutControl.value);
    const end = new Date(this.dateFinControl.value);

    this.modalService.dismissAll();
    this.updateListRdv = false;
    this.RdvService.updateEvenments(
      this.selected_rdv.id,
      this.eventTitleControl.value,
      start.toISOString(),
      end.toISOString()
    ).subscribe(
      (data) => {
        this.successAlert(this.alerts.updateEvent);
        this.RechargerListeRdv();
      },
      (error) => {
        if (error.startsWith('Error Code: 304')) this.errorInfo();
        else this.errorAlert(this.alerts.updateEvent, error);
        this.updateListRdv = true;
      }
    );
  }

  successAlert(data) {
    swal.fire({
      title: data,
      text: this.alerts.Successoperation,
      icon: 'success',
      showConfirmButton: true,
    });
  }

  errorAlert(data, err) {
    swal.fire({
      title: data,
      text: this.alerts.failedOperation,
      icon: 'error',
      showConfirmButton: true,
    });
  }

  errorInfo() {
    swal.fire({
      title: this.alerts.noModif,
      confirmButtonText: this.buttons.annuler,
      icon: 'info',
    });
  }

  deleteRdv() {
    this.modalService.dismissAll();
    this.updateListRdv = false;

    this.RdvService.deleteEvenments(this.selected_rdv.id).subscribe(
      (data) => {
        this.successAlert(this.alerts.eventDeleted);
        this.RechargerListeRdv();
      },
      (error) => {
        this.errorAlert(this.alerts.eventDeleted, error);
        this.updateListRdv = true;
      }
    );
  }

  createRdvDetails() {
    if (this.dateDebutControl.value === null || this.dateFinControl.value === null) {
      this.errorAlert(this.alerts.wrongDate, '');
      return;
    }
    if (this.dateFinControl.value < this.dateDebutControl.value) {
      this.errorAlert(this.alerts.dateSupp, '');
      return;
    }
    if (this.eventTitleControl.value === null || this.eventTitleControl.value === '') {
      this.errorAlert(this.alerts.titreOblig, '');
      return;
    }
    const start = new Date(this.dateDebutControl.value);
    const datestart = start.toISOString();
    const end = new Date(this.dateFinControl.value);
    this.modalService.dismissAll();
    this.updateListRdv = false;
    this.RdvService.createEvenments(this.eventTitleControl.value, start.toISOString(), end.toISOString()).subscribe(
      (data) => {
        this.successAlert(this.alerts.eventAdded);
        this.RechargerListeRdv();
      },
      (error) => {
        this.updateListRdv = true;
        this.errorAlert(this.alerts.eventAdded, error);
      }
    );
  }
}
