<div class="row">
    <div style="position: fixed;    z-index: 5;">
        <app-filter-maileva 
        [collapseFromParent]="collapseFromParent" 
        (collapseCloseToparent)="getCollapseValue($event)"
        (onSubmitFilter)="getListMaileva($event)" 
        [filterSsearch]="filterSsearch" 
        (onPushFilters)="EmitFilters($event)">
        </app-filter-maileva>
    </div>
</div>

<div class="row" #myDiv>

    <!-- Datatables -->
    <div class="col-lg-12">
        <div class="card mb-4 border-0" style="border-radius: 13px;">
            <div class="row display-flex justify-content-center align-items-center">
                <div class="col-3 d-block ">
                    <button type="button" class="btn-dt-add  ml-4" routerLink="add" [attr.data-title]="'languages.communication.addCourrier' | translate">
                        <i class="fa-solid fa-plus"></i>
                    </button>
                </div>
                <div class="col-6 blocDate mt-2">
                    <div class="row d-flex justify-content-center align-items-center">
                        <!-- <div class="col-5">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>{{'label.debutdate' | translate}}</mat-label>
                                <input matInput [matDatepicker]="pickerDebut" id="date_debut" name="date_debut" [(ngModel)]="date_debut" [value]="date_debut">
                                <mat-datepicker-toggle matSuffix [for]="pickerDebut"></mat-datepicker-toggle>
                                <mat-datepicker #pickerDebut></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-5">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>{{'label.enddate' | translate}}</mat-label>
                                <input matInput [matDatepicker]="pickerFin" id="date_fin" name="date_fin" [(ngModel)]="date_fin" [value]="date_fin | date:'yyyy-MM-dd'">
                                <mat-datepicker-toggle matSuffix [for]="pickerFin"></mat-datepicker-toggle>
                                <mat-datepicker #pickerFin></mat-datepicker>
                            </mat-form-field>
                        </div> -->
                        <div class="col-2">
                            <img src="assets/icons/calendarListing.svg" class="mr-3" alt="">
                    </div>
                        <div class="col-8">

                              <mat-form-field appearance="fill" class="w-100">
                                <mat-date-range-input [rangePicker]="picker"  >
                                  <input matStartDate [(ngModel)]="date_debut" readonly placeholder="{{'languages.convention.DateDeb' | translate}}"  (click)="openDatePicker()" />
                                  <input matEndDate [(ngModel)]="date_fin" readonly placeholder="{{'languages.convention.DateFin' | translate}}" (click)="openDatePicker()"/>

                                </mat-date-range-input>

                                <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="picker">    <mat-icon matDatepickerToggleIcon>
                                  <img src="assets/icons/calendar.svg" alt="calendarIcon" >
                                </mat-icon></mat-datepicker-toggle>
                                <img  class="remouveDate" matSuffix alt="removeIcon" (click)="clearDateCreation()" src="assets/icons/delete-left.svg">
                                <mat-date-range-picker  [calendarHeaderComponent]="rangeDateCreation" #picker></mat-date-range-picker>
                              </mat-form-field>

                          </div>
                        <div class="col-2 w-100 pl-0 ">
                            <button type="button" class="filtrer" (click)="getListMaileva('filtrer')">
                                <img src="assets/icons/filtre.svg" class="mr-3" alt="">
                 {{'languages.buttons.filtrer' | translate}}
              </button>
                        </div>
                    </div>
                </div>

                <div class="col-3 text-right">
                    <input class="search" name="" [ngModel]="sSearch"  (input)="onInputChange($event.target.value)" placeholder="{{'languages.miniListing.search' | translate}}">
                    <i aria-hidden="true" class="fa-solid fa-magnifying-glass icon-search fa-md"></i>
                </div>
            </div>
            <div class="row row-info">
                <div class="card-info-1" (click)="setStatusFilter('total')">
                    <p class="p-info-1">{{maileva.iTotalCourrierRecords}}</p>
                    <p class="p-info-2"> {{'languages.communication.allCourriers' | translate}}</p>
                </div>
                <div class="card-info-2" (click)="setStatusFilter('Envoyé')">
                    <p class="p-info-1">{{maileva.iTotalEnvoyeRecords}}</p>
                    <p class="p-info-2"> {{'languages.communication.sent' | translate}}</p>
                </div>
                <div class="card-info-3" (click)="setStatusFilter('Planifié')">
                    <p class="p-info-1">{{maileva.iTotalplanifieRecords}}</p>
                    <p class="p-info-2"> {{'languages.communication.planned' | translate}} </p>
                </div>
                <div class="card-info-4" (click)="setStatusFilter('Echoué')">
                    <p class="p-info-1">{{maileva.iTotalEchoueRecords}}</p>
                    <p class="p-info-2"> {{'languages.communication.echouee' | translate}}</p>
                </div>
            </div>


            <div class="table-responsive p-3 ">
                <div *ngIf="loading" class="row body-table d-flex justify-content-center align-items-center ">
                    <div class="spinner-style ">
                        <mat-spinner [diameter]="30"></mat-spinner>
                    </div>

                </div>

                <table class="table align-items-center "  id="dataTable">


                    <thead class="head-table">
                        <tr>
                            <th>
                              {{'languages.listing.actions' | translate}}
                            </th>
                            <!-- <th>Création
                                <div (click)="setSortField(1)" class="d-inline-block" [ngClass]="{'sort-up':checkSort(1) }">
                                                                        <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>

                                </div>
                            </th> -->
                            <th>  {{'languages.listing.oppAff' | translate}}
                            </th>
                            <th>{{'languages.alerts.subject' | translate}}

                            </th>
                            <th>{{'languages.communication.typeEnvoie' | translate}}
                                <div (click)="setSortField(5)" class="d-inline-block" [ngClass]="{'sort-up':checkSort(5) }">
                                    <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>

                                </div>
                            </th>
                            <th> {{'languages.communication.sendDate' | translate}}
                                <div (click)="setSortField(0)" class="d-inline-block" [ngClass]="{'sort-up':checkSort(0) }">
                                    <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>
                                </div>
                            </th>
                            <!-- <th>Date de Réception
                                <div (click)="setSortField(3)" class="d-inline-block" [ngClass]="{'sort-up':checkSort(0) }">
                                                                        <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>

                                </div>
                            </th> -->


                            <th>{{'languages.communication.exp' | translate}}
                                <div (click)="setSortField(0)" class="d-inline-block" [ngClass]="{'sort-up':checkSort(0) }">
                                    <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>
                                </div>
                            </th>
                            <th>{{'languages.communication.destinataire' | translate}}
                                <div (click)="setSortField(6)" class="d-inline-block" [ngClass]="{'sort-up':checkSort(6) }">
                                    <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>
                                </div>
                            </th>
                            <th>{{'languages.communication.adressDesti' | translate}}
                                <div (click)="setSortField(7)" class="d-inline-block" [ngClass]="{'sort-up': checkSort(7) }">
                                    <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>
                                </div>
                            </th>
                            <th> {{'languages.listing.Statut' | translate}}</th>
                        </tr>
                    </thead>


                    <tbody class="body-table">

                        <tr *ngFor="let mail of maileva.aaData">
                            <td>
                                <button type="button" class="btn-dt-list"  (click)=" goToEdit()"
                                    routerLink="/maileva/details/{{mail.id_md5}}/">
                                    <img src="/assets/icons/edit-icon.svg">
                                </button>
                                <a target="_blank" href="{{mail.file_courrier}}">
                                    <button type="button" class="btn-dt-list">
                                        <img src="/assets/icons/pdf-icon.svg">
                                    </button>
                                </a>
                            </td>
                            <!-- <td>
                                {{mail.date| date:'dd-MM-yyyy HH:mm' }}
                            </td> -->
                            <td>
                                <div class="d-flex flex-column">
                                    <div
                                        *ngIf="mail.id_affaire_md5 == null && mail.url_dossier == null && mail.id_opp_md5 == null">
                                    </div>
                                    <div *ngIf="mail.id_affaire_md5 !== null ">
                                        <a target="_blank" [routerLink]="['/affaires/details/', mail.id_affaire_md5]">
                                        <button type="button" class="btn-dt-link text-left"
                                            (click)="clickAffaire(mail.id_affaire)">
<i class="fa-solid fa-link fa-icon-style"></i>
                                            {{'languages.listing.Affaire' | translate}}
                                        </button>
                                    </a>
                                    </div>
                                    <div *ngIf="mail.url_dossier !== null">
                                        <a href="{{mail.url_dossier}}">
                                            <button type="button" class="btn-dt-link text-left">
<i class="fa-solid fa-link fa-icon-style"></i>
                                                {{'languages.listing.Dossier' | translate}}
                                            </button>
                                        </a>
                                    </div>
                                    <div *ngIf="mail.id_opp_md5 !== null">
                                        <a target="_blank" [routerLink]="['/opportunities/details/', mail.id_opp_md5]">
                                        <button type="button" class="btn-dt-link text-left "
                                            (click)="clickOpportunite(mail.id_opp)">
<i class="fa-solid fa-link fa-icon-style"></i>
                                            {{'languages.listing.Opportunite' | translate}}
                                        </button>
                                        </a>
                                    </div>
                                </div>
                            </td>
                            <td>
                                {{expandFileName(mail.subject)}}
                            </td>
                            <td>
                                {{mail.type_envoie}}
                            </td>


                            <td *ngIf="format_date(mail.date_envoie)">
                                {{mail.date_envoie}}
                            </td>
                            <td *ngIf="!format_date(mail.date_envoie)">

                            </td>

                            <!-- <td *ngIf="format_date(mail.date_livraison)">
                                {{ mail.date_livraison}}
                            </td>
                            <td *ngIf="!format_date(mail.date_livraison)">
                            </td> -->

                            <td>
                                {{mail.nomprenom_exp}}
                            </td>
                            <td>
                                {{mail.nomprenom_dest}}
                            </td>
                            <td>
                                <p>
                                    {{ mail.postal_code_post + ' ' + mail.ville_dest }}
                                </p>
                            </td>
                            <td>
                                <!-- <div class="d-inline-block label-status p-1" tabindex="0" data-toggle="tooltip"
                  title="{{mail?.statut_details?.description}}" [style.background]="mail?.statut_details?.background">
                    <p  [style.color]="mail.statut_details?.color">
                      {{mail.statut_details?.libelle}}
                    </p>
                </div> -->
                                <div *ngIf="mail.statut_details?.libelle"
                                    class="status-maileva d-flex justify-content-center"
                                    [ngStyle]="{'background':mail?.statut_details?.backgroundcolor, 'border' : '2px solid ' + mail?.statut_details?.border  }">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <i class=" {{mail?.statut_details?.icon}} mr-2"  *ngIf="mail?.statut_details?.icon && mail?.statut_details?.icon !='None'"></i>
                                        {{mail.statut_details?.libelle}}
                                    </div>
                                </div>
                            </td>

                        </tr>
                    </tbody>
                </table>

            <div class="row d-flex justify-center align-items-center text-center ">
                <p *ngIf="!loading && maileva.aaData.length===0"  class="col-12 noTicket">{{'languages.communication.noCourrier' | translate}} </p>
               </div>

            </div>

        </div>
        <div *ngIf="!loading" class="navbar-center mb-4 row">
            <div class="col pt-1">
                <div class="row w-50 flex-nowrap">
                    <div class="col mt-1">
                        <p>{{'languages.miniListing.Afficher' | translate}}</p>
                    </div>
                    <div class="col mx-1">
                        <select [(ngModel)]="limit" (change)="updateListMaileva()"
                            class="bg-white border pt-1 rounded font-weight-bold">
                            <option value="15">15</option>
                            <option value="25">25</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <div class="col mt-1">
                        <p class="text-nowrap">{{'languages.miniListing.pagesPerPage' | translate}} {{totalLength}} {{'languages.miniListing.element' | translate}}</p>
                    </div>
                </div>
            </div>
            <div class="col-8">
                <nav aria-label="Page navigation example" *ngIf="totalPages>0">
                    <ul class="pagination pg-blue">
                        <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}">
                            <a class="page-link-symbol">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>
                        <li *ngIf="!isFirst()" (click)="prev()" class="page-item mx-2">
                            <a class="page-link-symbol-chevron" aria-label="Previous">
                                <i class="fa fa-chevron-left "></i>
                            </a>
                        </li>
                        <ng-container *ngFor="let item of [].constructor(3); let i = index">
                            <li *ngIf="i+Page-3>0" class="page-item">
                                <a (click)="ChangePage(i+Page-3)" class="page-link mt-2 border rounded">{{i + Page -
                                    3}}</a>
                            </li>
                        </ng-container>
                        <ng-container *ngFor="let item of [].constructor(3); let i = index">
                            <li *ngIf="i+Page<totalPages" class="page-item">
                                <a (click)="ChangePage(i+Page)" class="page-link mt-2 border rounded"
                                    [ngClass]="{'active-page':Page==(i+Page)}">
                                    {{i + Page}}
                                </a>
                            </li>
                        </ng-container>
                        <li *ngIf="totalPages > 3 " class="m-2"> ... </li>
                        <li class="page-item">
                            <a (click)="ChangePage(totalPages)" class="page-link mt-2 border rounded"
                                [ngClass]="{'active-page':Page==totalPages}">{{totalPages}}</a>
                        </li>
                        <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2">
                            <a class="page-link-symbol-chevron" aria-label="Next">
                                <i class="fa fa-chevron-right"></i>
                            </a>
                        </li>
                        <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}">
                            <a class="page-link-symbol">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col">
                <div class="row position-absolute flex-nowrap" style="right: 0px">
                    <div class="col text-nowrap text-right mt-2">
                        <p>{{'languages.listing.label_goto' | translate}}</p>
                    </div>
                    <div class="col mt-1 ml-2">
                        <input [(ngModel)]="intervale" size="1" min="1" onkeypress="return event.charCode >= 48"
                            (keyup.enter)="getchangeLimite()" (change)="getchangeLimite()" type="number"
                            style="margin-left: 4px;" size="1"
                            class="page_input bg-white border rounded font-weight-bold" [value]="Page"
                            max="{{totalPages}}">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal View Details -->

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <ng-container *ngIf="selectedMaileva">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        {{selectedMaileva.nomprenom_dest}}#{{selectedMaileva.id}}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    ...
                </div>
            </ng-container>
        </div>
    </div>
</div>
