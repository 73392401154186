import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
 
import { Comments } from 'src/app/entity/Comments';
import { CommentaireServicesService } from 'src/app/services/ApiCommentaires/commentaire-services.service';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { ApiTicketsService } from 'src/app/services/ApiTickets/api-tickets.service';
import { TranslateService } from '@ngx-translate/core';
import { HistoryService } from 'src/app/pages/affaires/affaire-details/history.service';
import { HistoryServiceTicket } from 'src/app/pages/tickets-and-support/history.service';

@Component({
  selector: 'app-add-commentaire',
  templateUrl: './add-commentaire.component.html',
  styleUrls: ['./add-commentaire.component.css'],
})
export class AddCommentaireComponent implements OnInit {
  @Output() commentaireExport: EventEmitter<any> = new EventEmitter();
  addCommentaire: boolean = false;
  listComments: Comments[] = [];
   
  form: FormGroup;
  prospect_id: string;
  comment: any;
  show_loading_add: boolean = false;
  entity_id: string = '';
  fileList: File[] = [];
  isCollapsed: boolean[] = [];
  index: number = 0;
  @Input() fromType;
  @Input() id_opportunite;
  @Input() allowed_to_update;
  file: any;
  ListFiles: any;
  alerts: any;

  loading: boolean = false;
  showTypeDoc: boolean;
  constructor(
    private fb: FormBuilder,
    private routes: ActivatedRoute,
    private apiTicketsServices: ApiTicketsService,
    private notificationsService: NotificationsService,
    private translate: TranslateService,
    private HistoryService: HistoryService,
    private HistoryServiceTicket: HistoryServiceTicket,
    private apiCommentraieService: CommentaireServicesService
  ) {}

  ngOnInit(): void {
    this.show_loading_add = true;
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
      });});
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
      });

    this.routes.paramMap.subscribe((params) => {
      if (params.get('affaire_id')) {
        this.entity_id = params.get('affaire_id');
      } else if (params.get('tickets_id')) {
        this.entity_id = params.get('tickets_id');
      } else {
        this.entity_id = this.id_opportunite;
      }

      this.getComments(this.entity_id, this.fromType);
    });

    this.form = this.fb.group({
      comment: [''],
    });
  }

  getComments(id_entity, entity) {
    this.show_loading_add = true;
    this.apiCommentraieService.getListCommentaire(id_entity, entity).subscribe(
      (data: Comments[]) => {
    
        this.listComments = data.reverse();
        this.commentaireExport.emit(this.listComments);
       
        this.listComments.forEach(() => this.isCollapsed.push(true));
        this.show_loading_add = false;
      },
      (error) => {
        console.log(error);
        this.show_loading_add = false;
      }
    );
  }

  toggleReadMore(index: number) {
    this.isCollapsed[index] = !this.isCollapsed[index];
  }

  expandName(name: string): string {
    name = name?.trim();
    if (name?.length < 10) {
      return name;
    } else {
      return name?.substring(0, 10) + '...';
    }
  }

  onSubmit() {
    const formData: FormData = new FormData();
    formData.append('entity', this.fromType);
    formData.append('id_entity', this.entity_id);
    formData.append('commentaire', this.form.value.comment);
    for (let index = 0; index < this.fileList.length; index++) {
      formData.append('docs', this.fileList[index]);
    }
    this.apiCommentraieService.createCommentaire(formData).subscribe(
      (response) => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.success;
        notificationMessage.title = this.alerts.Successoperation;
        notificationMessage.message = response.message;
        this.notificationsService.sendMessage(notificationMessage);
        this.form.reset();
        this.getComments(this.entity_id, this.fromType);
        if (this.fromType ==="A"){
          this.HistoryService.sendFetchDataSignal(true)
        }else if (this.fromType ==="T"){
          this.HistoryServiceTicket.sendFetchDataSignal(true)
        }
        
      },
      (error: any) => {
        if (error.status === 406) {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = this.alerts.Operationechoue;
          notificationMessage.message = this.alerts.FailAddProspect;
          this.notificationsService.sendMessage(notificationMessage);
          this.show_loading_add = false;
        } else {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = this.alerts.Operationechoue;
          notificationMessage.message = this.alerts.error;
          this.notificationsService.sendMessage(notificationMessage);
          this.show_loading_add = false;
        }
      }
    );

    this.show_loading_add = true;
    this.addCommentaire = false;
    this.comment = {
      entity: this.fromType,
      id_entity: this.entity_id,
      commentaire: this.form.value.comment,
    };
    this.fileList = [];
    // this.apiCommentraieService.createCommentaire(this.comment)
    //   .subscribe(
    //     (response) => {
    //       let notificationMessage = new NotificationMessage();
    //       notificationMessage.type = NotificationType.success
    //       notificationMessage.title = "Opération réussite";
    //       notificationMessage.message = response.message ;
    //       this.notificationsService.sendMessage(notificationMessage)
    //       this.form.reset();
    //       this.getComments(this.entity_id ,this.fromType );
    //       this.show_loading_add = false
    //     },
    //     (error: any) => {
    //       if (error.status === 406) {
    //         let notificationMessage = new NotificationMessage();
    //         notificationMessage.type = NotificationType.error
    //         notificationMessage.title = "Opération echoué";
    //         notificationMessage.message = "Aucun add_prospect ne correspant" ;
    //         this.notificationsService.sendMessage(notificationMessage)
    //         this.show_loading_add = false
    //       }
    //       else {
    //         let notificationMessage = new NotificationMessage();
    //         notificationMessage.type = NotificationType.error
    //         notificationMessage.title = "Opération echoué";
    //         notificationMessage.message = "Erreur" ;
    //         this.notificationsService.sendMessage(notificationMessage)
    //         this.show_loading_add = false
    //       }
    //     });
  }

  AddComm() {
    this.addCommentaire = !this.addCommentaire;
    this.showTypeDoc=false
    this.fileList = [];
    this.form.get('comment').setValue('');
  }

  removefile(file) {
    this.fileList.forEach((element, index) => {
      if (element.name === file.name) this.fileList.splice(index, 1);
    });
  }

  private isValidFileType(files): boolean {
    const allowedFileTypes = ['pdf', 'jpg', 'png', 'jpeg', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'zip', 'odt', 'txt'];

  
      const fileExtension = this.getFileExtension(files.name).toLowerCase();
      if (!allowedFileTypes.includes(fileExtension)) {
        return false;
      }
   

    return true;
  }

  private getFileExtension(filename: string): string {
    return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
  }

  
  onFilechange(event: any) {
    this.loading = true;
    const files = event.target.files || event.dataTransfer.files; // Check for files in the event dataTransfer if it's a drop event

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (this.isValidFileType(file)) {
        // Your logic for handling the files goes here
        this.showTypeDoc=false 
        if (file.type.includes('image/')) {
          const reader = new FileReader();
  
          reader.onload = () => {
            const img = new Image();
            img.src = reader.result as string;
            file.path = reader.result;
            file.types = 'image';
  
            // Perform any image-specific actions here
          };
  
          reader.readAsDataURL(file);
        } else {
          file.types = 'file';
          file.path = '';
  
          // Perform any file-specific actions here
        }
  if (file){
    this.loading = false;
  }
        this.fileList.push(file);
     
      
      } else {
        this.showTypeDoc=true 
        this.loading = false;
      }

  }}


  
  onDragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.addDropEffect();
  }

  onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.removeDropEffect();
    this.onFilechange(event);

  }

  addDropEffect() {
    const container = document.getElementById('file-upload-container');
    container.classList.add('drag-over'); // Add a CSS class for styling during drag-over
  }

  removeDropEffect() {
    const container = document.getElementById('file-upload-container');
    container.classList.remove('drag-over'); // Remove the CSS class when drag-over ends
  }

  expandFileName(name: string): string {
    if (name.length < 10) {
      return name;
    } else {
      return name.substring(16, name.length);
    }
  }
}
